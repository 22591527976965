<template>
  <el-dialog
    :title="isEdit ? '修改目标水位' : '添加目标水位'"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="reset"
  >
    <div class="form_box">
      <div class="water_level_box">
        <div class="item">
          <span>大于</span>
          <input type="text" maxlength="2" v-model="form.min" @input="ipt('min')" />
          <span>％开启</span>
        </div>

        <div class="item">
          <span>小于</span>
          <input type="text" maxlength="2" v-model="form.max" @input="ipt('max')" />
          <span>％关闭</span>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { AddPreConfig, UpdatePreConfig } from "@/api";

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 运营商
      id_project: "",
      form: {
        max: "",
        min: "",
      },
      isEdit: false,
      // 修改项id
      id: "",
    };
  },
  methods: {
    // 重置
    reset() {
      this.form.max = this.form.min = this.form.remark = "";
    },

    // 限制只能输入数字
    ipt(key) {
      this.form[key] = this.form[key].replace(/[^\d]/g, "");
    },

    // 添加目标水位
    add(orgId) {
      this.isEdit = false;
      this.id_project = orgId;
      this.dialogVisible = true;
    },

    // 修改预设水位
    edit(item) {
      this.id = item.id;
      this.form.max = item.max + "";
      this.form.min = item.min + "";
      this.form.remark = item.remark;
      this.isEdit = true;
      this.dialogVisible = true;
    },

    // 确定 level_target_array
    confirm() {
      if (this.form.min === "" || this.form.max === "") {
        return this.$message.warning("请输入预设水位/目标水位");
      }
      if (Number(this.form.max) <= Number(this.form.min)) {
        this.$message.warning("目标水位需大于预设水位");
        return;
      }
      if (this.isEdit) {
        this.updateWaterLevel();
      } else {
        this.addWaterLevel();
      }
    },

    // 添加目标水位
    async addWaterLevel() {
      const params = {
        id_project: this.id_project,
        level_target_array: [
          {
            min: this.form.min / 1,
            max: this.form.max / 1,
            remark: this.form.remark,
          },
        ],
      };
      const { ret, data, msg } = await AddPreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "添加目标水位失败!");
      }
      this.$message.success("添加目标水位成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },

    // 更新目标水位
    async updateWaterLevel() {
      const params = {
        id: this.id,
        param_type: 1,
        max: this.form.max / 1,
        min: this.form.min / 1,
        remark: this.form.remark,
      };
      const { ret, data, msg } = await UpdatePreConfig(params);
      if (ret !== 0) {
        return this.$message.error(msg || "修改目标水位失败!");
      }
      this.$message.success("修改目标水位成功");
      this.$emit("refresh");
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form_box {
  width: 480px;
  margin: 0 auto;

  .water_level_box {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 12px;

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin: 0 6px;
      }

      input {
        width: 64px;
        height: 32px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        outline: none;
        text-align: center;
      }
    }
  }
}
</style>
